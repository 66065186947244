enum ServerErrorCodes {
	INSUFFICIENT_FUNDS = 10050027,
	SESSION_NOT_FOUND = 10010010,
}

const ServerErrors = {
	[ServerErrorCodes.INSUFFICIENT_FUNDS]: 'INSUFFICIENT FUNDS',
	[ServerErrorCodes.SESSION_NOT_FOUND]: 'SESSION NOT FOUND',
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { ServerErrorCodes, ServerErrors };
