import trimEnd from 'lodash/trimEnd';
import { BASE_LANG } from './constants';
import debug from './debug';
import { getI18n, initI18n } from './instance';
import { Internationalization } from './Internationalization';

interface II18nLoadOptions {
	// Load the core (library) language files? Defaults to true.
	loadCoreLang?: boolean;

	// The sub path relative the /locales. Defaults to core/
	coreSubPath?: string;

	// Optional Internationalization (i18n) override.
	i18nInstance?: Internationalization;
}

/**
 * Helper - First loads english as the base before loading the additional languages.
 *
 * Files are loaded in order:
 * 	- /locales/core/base-en
 *  - /locales/base-en
 *  - /locales/core/{other lang}
 *  - /locales/{other lang}
 *
 * @param lang the language key such as 'sp', 'es', 'de'
 * @returns Promise
 */
const loadI18n = async (lang?: string, opts?: II18nLoadOptions): Promise<boolean> => {
	lang = lang || BASE_LANG;
	opts = opts ?? {};

	const loadCoreLang = opts.loadCoreLang ?? false;
	const coreSubPath = opts.coreSubPath ? trimEnd(opts.coreSubPath, '/') : 'core';

	let i18nInstance: Internationalization;
	if (opts.i18nInstance) {
		i18nInstance = opts.i18nInstance;
	} else {
		initI18n();
		i18nInstance = getI18n();
	}

	if (i18nInstance.languages.includes(lang)) {
		debug.error(`Language '${lang}' is already loaded`, 'loadI18n');
		return false;
	}

	debug.info(`Loading language '${lang}'. Options are:`, 'loadI18n', { loadCoreLang, coreSubPath, i18nInstance });

	// Load the core base language file
	if (loadCoreLang) {
		debug.info(`Loading core language file for '${BASE_LANG}'`, 'loadI18n');
		await i18nInstance.load(`${coreSubPath}/${BASE_LANG}`);
	}

	// Load the client's base language file
	debug.info(`Loading client language file for '${BASE_LANG}'`, 'loadI18n');
	await i18nInstance.load(BASE_LANG);

	// Stop if we have nothing more to load
	if (lang === BASE_LANG) {
		return true;
	}

	// Load the library (core) language file for the specific language.
	if (loadCoreLang) {
		debug.info(`Loading core language file for '${lang}'`, 'loadI18n');
		await i18nInstance.load(`${coreSubPath}/${lang}`);
	}

	// Load the games language file for the specific language.
	debug.info(`Loading client language file for '${lang}'`, 'loadI18n');
	await i18nInstance.load(lang);

	return true;
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { loadI18n };
