import { Internationalization } from './Internationalization';

const newInstance = () => {
	return new Internationalization();
};

let _isInitialized = false;
let _instance: Internationalization = newInstance();

const getInstance = () => {
	return _instance;
};

const setInstance = (instance: Internationalization) => {
	_instance = instance;
};

const init = () => {
	if (_isInitialized) {
		return;
	}

	_instance = newInstance();
	_isInitialized = true;
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { getInstance, setInstance, getInstance as getI18n, setInstance as setI18n, init as initI18n };
