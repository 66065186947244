import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { loadI18n } from 'i18n';
import 'helpers/window';
import { reportWebVitals } from './reportWebVitals';
import { setupServer } from './server';
import './index.css';

const docRoot = 'root';
const App = lazy(() => import('./App'));

/**
 * Creates & initializes the application and returns a method to render.
 *
 * @returns
 */
const createApp = () => {
	return {
		render() {
			ReactDOM.render(
				<React.StrictMode>
					<Suspense fallback={<div>Loading</div>}>
						<App />
					</Suspense>
				</React.StrictMode>,
				document.getElementById(docRoot)
			);
		},
	};
};

/**
 * Pre-app render initialization. This should be used to do things before the React app runs for the first time.
 *
 * @returns A promise that should resolve before the application should be allowed to load.
 */
const init = async () => {
	// Initializes the integration instance.
	// setupIntegration();

	reportWebVitals(console.log);

	// Initializes the session, stores, services, streams, etc.
	setupServer({ isDebugEnabled: true });

	// Load i18n language translation
	await loadI18n();
};

// Initialize
init()
	.then(() => {
		const app = createApp();
		app.render();
	})
	.catch((reason: any) => {
		console.error(reason);

		ReactDOM.render(
			<div>
				Could not load application {JSON.stringify(reason)} {reason}
			</div>,
			document.getElementById(docRoot)
		);
	});
