import isArray from 'lodash/isArray';
import trimStart from 'lodash/trimStart';
import { generateRandomString, renderTemplate } from '@sandsb2b/sds-client-shared/dist/string';
import { KEY_TEST_MODE, LIVE_TEXT, MISSING_VAL } from './constants';
import debug from './debug';
import { I18nData, I18nDataItem } from './types';

class Internationalization {
	// All loaded languages
	/**
	 * All loaded languages.
	 */
	public languages: string[] = [];

	/**
	 * Translation data.
	 */
	protected _data: I18nData = {};

	/**
	 * Default path to load the language files from.
	 */
	public readonly defaultPath = '/locales';

	/**
	 * When TRUE will return the missing value instead of the raw untranslated thing when unable to translate.
	 */
	public returnMissingValue: boolean = false;

	/**
	 * Unique instanceId.
	 */
	protected _instanceId: string;

	/**
	 * CONSTRUCTOR
	 */
	constructor() {
		this._instanceId = generateRandomString();
		// debug.warn('CONSTRUCT', this._instanceId);
	}

	/**
	 * @returns The unique instance Id of this class instance.
	 */
	public get instanceId(): string {
		return this._instanceId;
	}

	/**
	 * Facades
	 */
	public t = (key: string) => this.translate(key);
	public l = (key: string) => this.list(key);
	public r = (key: string, replacements: StringDict) => this.replace(key, replacements);

	/**
	 * @returns The currently loaded translation data.
	 */
	public get data() {
		return this._data;
	}

	/**
	 * Loads the specified language file.
	 *
	 * @param lang the langauge key matching a language file such as 'en'
	 * @returns Promise
	 */
	public load = async (lang: string) => {
		const filePath = this.getFilePath(lang);
		const jsonFilePath = `${filePath}.json`;

		debug.info(`Loading language file for language '${lang}':`, 'load', jsonFilePath);

		const data = await this.loadFile(jsonFilePath);

		if (data != null) {
			this.merge(data);
			this.languages.push(lang);
			debug.info(`Loaded new language data:`, 'load', {
				path: jsonFilePath,
				data: data,
				composite: this.data,
				instanceId: this._instanceId,
			});
		}
	};

	public loadFile = async (filePath: string): Promise<Nullable<StringDict>> => {
		if (filePath === '') {
			return null;
		}

		try {
			const response =
				(await fetch(filePath, {
					headers: {
						'Content-Type': 'application/json',
						Accept: 'application/json',
					},
				})) ?? null;

			const data = (response ? response.json() : null) ?? {};

			return data as StringDict;
		} catch (e: unknown) {
			const err = e as Error;
			debug.warn(`Unable to load language file '${filePath}':`, 'loadFile', err, { instanceId: this._instanceId });
			return null;
		}
	};

	/**
	 * Determines the path to load the translation files from.
	 *
	 * @param lang the langauge key such as 'en'. This can also contain a path such as core/en.
	 * @returns string
	 */
	public getFilePath(lang: string): string {
		// If the string contains /locales for example, we'll assume the path is baked in.
		if (lang.includes(this.defaultPath)) {
			return lang;
		}

		const path = trimStart(lang, '/');

		// lang may also contain a subpath such as core. This would result in a string
		// such as /locales/core/en
		return `${this.defaultPath}/${path}`;
	}

	/**
	 * Clears the existing data.
	 */
	public clear = () => {
		this.languages = [];
		this._data = {};
	};

	/**
	 * Sets a key/value.
	 *
	 * @param key the key to set
	 * @param value the value to set
	 */
	public set = (key: string, value: I18nDataItem) => {
		key !== '' && (this._data[key] = value);
	};

	/**
	 * Merges new data into the class data object.
	 */
	// TODO: We should probably just use lodash `merge` and set the array merge strategy...
	public merge = (mergeData: I18nData): I18nData => {
		const data: I18nData = { ...this._data };

		Object.keys(mergeData).forEach((key: string) => {
			// If both values are arrays, we'll merge the new data into the existing data.
			if (isArray(data[key]) && isArray(mergeData[key])) {
				const newArr = (data[key] as string[]).slice();
				const mergeArr = (mergeData[key] ?? []) as string[];

				for (let i = 0, l = newArr.length; i < l; i++) {
					mergeArr[i] != null && (newArr[i] = mergeArr[i]);
				}

				data[key] = newArr;
				return;
			}

			data[key] = mergeData[key];
		});

		this._data = data;

		return this._data;
	};

	/**
	 * Returns the translated value for a given language key.
	 */
	public translate = (key: string): string => {
		if (key === '') {
			return '';
		}

		const value = this._data[key] ?? '';

		// No translation found
		if (value === '') {
			debug.warn(`MISSING: Key "${key}" not found. Returning as untranslated.`, 'translate', {
				instanceId: this._instanceId,
				languages: this.languages,
				data: this._data,
			});

			return this.returnMissingValue ? MISSING_VAL : key;
		}

		// Translated value is an array
		if (isArray(value)) {
			debug.warn(`INVALID: Key "${key}" resolves to an array. Returning as untranslated.`, 'translate', {
				instanceId: this._instanceId,
				languages: this.languages,
				data: this._data,
			});

			return this.returnMissingValue ? MISSING_VAL : key;
		}

		return KEY_TEST_MODE ? LIVE_TEXT : value;
	};

	/**
	 * Returns the translated value list for a given language key.
	 */
	public list = (key: string): string[] => {
		if (key === '') {
			throw new Error('Key must be specified as non-empty string');
		}

		const value = this._data[key] ?? null;

		// No translation found
		if (value == null) {
			debug.warn(`MISSING: Key "${key}" not found. Returning as untranslated.`, 'list', {
				instanceId: this._instanceId,
				languages: this.languages,
				data: this._data,
			});

			return this.returnMissingValue ? [MISSING_VAL] : [key];
		}

		if (!isArray(value) || (isArray(value) && value.length === 0)) {
			debug.warn(`INVALID: Key "${key}" does not resolve to a non-empty array. Returning as untranslated.`, 'list', {
				instanceId: this._instanceId,
				languages: this.languages,
				data: this._data,
			});

			return this.returnMissingValue ? [MISSING_VAL] : [key];
		}

		return KEY_TEST_MODE ? [LIVE_TEXT, LIVE_TEXT] : value;
	};

	/**
	 * Replaces a templated string item.
	 *
	 * @returns The substituted translated string
	 */
	public replace = (key: string, replacements: StringDict) => {
		if (key === '') {
			throw new Error('Key must be specified as non-empty string');
		}

		const value = this._data[key] ?? '';

		// No translation found
		if (value === '') {
			debug.warn(`MISSING: Key "${key}" not found. Returning as untranslated.`, 'replace', {
				instanceId: this._instanceId,
				languages: this.languages,
			});

			return this.returnMissingValue ? MISSING_VAL : key;
		}

		// Translated value is an array
		if (isArray(value)) {
			debug.warn(`INVALID: Key "${key}" resolves to an array. Returning as untranslated.`, 'replace', {
				instanceId: this._instanceId,
				languages: this.languages,
			});

			return this.returnMissingValue ? MISSING_VAL : key;
		}

		return KEY_TEST_MODE ? LIVE_TEXT : renderTemplate(value, replacements);
	};
}

// ---- Export --------------------------------------------------------------------------------------------------------

export { Internationalization as default };
export { Internationalization };
