/* eslint-disable @typescript-eslint/no-unused-vars */

/**********************************************************************************************************************
 * Setup & initialization for the server layer.
 *********************************************************************************************************************/
import { setupSdk } from './sdk';
import { setupStores } from './store';

interface ISetupServerOpts {
	isDebugEnabled?: Maybe<boolean>;
}

/**
 * All setup and initialization of server modules should be done here. This helps us prevent
 * race conditions and hot-reload related issues.
 */
const setupServer = (opts?: Maybe<ISetupServerOpts>) => {
	const isDebugEnabled = opts?.isDebugEnabled;

	// Setup the Client RPC SDK
	setupSdk({ isDebugEnabled });

	// Setup the stores layer
	setupStores({ isDebugEnabled });
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { setupServer };
