// Turn on to replace all found string with the live text value
const KEY_TEST_MODE = false;

// Value used when translation is missing
const MISSING_VAL = 'MiSsInG';

// Value used when KEY_TEST mode is TRUE
const LIVE_TEXT = 'LiVe TeXt';

// Enum of all available languages
enum Languages {
	ENGLISH = 'en',
	SPANISH = 'es',
	GERMAN = 'de',
	DUTCH = 'nl',
	DANISH = 'da',
	SWEDISH = 'sv',
	FINNISH = 'fi',
	NORWEGIAN = 'no',
	FRENCH = 'fr',
	POLISH = 'pl',
	CZECH = 'cs',
	ITALIAN = 'it',
	PORTUGESE = 'pt',
	TURKISH = 'tr',
	CHINESE = 'zh',
	THAI = 'th',
	VIETNAMESE = 'vi',
	KOREAN = 'ko',
	JAPANESE = 'ja',
	TAGALOG = 'tl',
	ARABIC = 'ar',
	FARSI = 'fa',
	SLOVAKIAN = 'sk',
	ROMANIAN = 'ro',
	GREEK = 'el',
	HINDI = 'hi',
	PAKISTANI = 'ur',
	RUSSIAN = 'ru',
}

// Dictionary of languages to names
const LanguageNames = {
	[Languages.ENGLISH]: 'English',
	[Languages.SPANISH]: 'Spanish',
	[Languages.GERMAN]: 'German',
	[Languages.DUTCH]: 'Dutch',
	[Languages.DANISH]: 'Danish',
	[Languages.SWEDISH]: 'Swedish',
	[Languages.FINNISH]: 'Finnish',
	[Languages.NORWEGIAN]: 'Norwegian',
	[Languages.FRENCH]: 'French',
	[Languages.POLISH]: 'Polish',
	[Languages.CZECH]: 'Czech',
	[Languages.ITALIAN]: 'Italian',
	[Languages.PORTUGESE]: 'Portuguese',
	[Languages.TURKISH]: 'Turkish',
	[Languages.CHINESE]: 'Chinese',
	[Languages.THAI]: 'Thai',
	[Languages.VIETNAMESE]: 'Vietnamese',
	[Languages.KOREAN]: 'Korean',
	[Languages.JAPANESE]: 'Japanese',
	[Languages.TAGALOG]: 'Tagalog',
	[Languages.ARABIC]: 'Arabic',
	[Languages.FARSI]: 'Farsi',
	[Languages.SLOVAKIAN]: 'Slovakian',
	[Languages.ROMANIAN]: 'Romanian',
	[Languages.GREEK]: 'Greek',
	[Languages.HINDI]: 'Hindi',
	[Languages.PAKISTANI]: 'Pakistani',
	[Languages.RUSSIAN]: 'Russian',
};

const BASE_LANG = Languages.ENGLISH;

// ---- Export --------------------------------------------------------------------------------------------------------

export { KEY_TEST_MODE, MISSING_VAL, LIVE_TEXT, BASE_LANG };
export { Languages, LanguageNames };
