/**********************************************************************************************************************
 * Initialization of the various stores used by the player client (via the SDK).
 *********************************************************************************************************************/
import { IPlayStore, IStores } from 'server/lib-rpc';
import { getSdk } from '../sdk';

// ---- Setup ---------------------------------------------------------------------------------------------------------

interface ISetupStoresOpts {
	isDebugEnabled?: Maybe<boolean>;
}

/**
 * Setup the stores used by the player client and binds them to MobX.
 *
 * @returns StoreManager instance containing the stores.
 */
const setupStores = (opts?: Maybe<ISetupStoresOpts>) => {
	const isDebugEnabled = opts?.isDebugEnabled;

	if (isDebugEnabled === true) {
		const sds = window.sds ?? {};
		sds.getStores = (): IStores<IPlayStore> => getStores();
	}
};

// ---- Utilities -----------------------------------------------------------------------------------------------------

/**
 * @returns Key-value object of all store instances used by the player client.
 */
const getStores = (): IStores<IPlayStore> => {
	const sdk = getSdk();

	return {
		...sdk.stores.getStores(),
	};
};

/**
 * Clears all player client stores instances.
 */
const clearStores = (): void => {
	const sdk = getSdk();

	sdk.actions.store.clearAllStores();
};

// ---- Exports -----------------------------------------------------------------------------------------------------

export { clearStores, getStores, setupStores };
